<!-- 区企互动 -->
<template>
  <div class="pinglun">
    <div>
      <van-field
        v-model="datafrom.AIdName"
        label="中介机构"
        required
        placeholder="请选择中介机构"
        @click="typeshow = true"
        readonly="readonly"
      />
      <van-popup v-model="typeshow" position="bottom" get-container="body">
        <van-picker
          show-toolbar
          :columns="aidList"
          value-key="Name"
          @cancel="typeshow = false"
          @confirm="ontypeshow"
        >
        </van-picker>
      </van-popup>
      <div style="margin-top: 10px">
        <span class="inputLabel">服务评分</span>
        <van-rate
          v-model="datafrom.StarLevel"
          :size="20"
          :gutter="10"
          color="#FF9E14"
          style="margin-left: 30px"
        />
      </div>
      <van-field
        v-model="datafrom.Content"
        rows="3"
        autosize
        label="服务评价"
        type="textarea"
        placeholder="请输入服务评价"
        style="margin-top: 15px"
      />
      <van-field
        v-model="datafrom.Remark"
        rows="3"
        autosize
        label="备注"
        type="textarea"
        placeholder="请输入备注"
        style="margin-top: 15px"
      />
    </div>
    <div class="fromButton">
      <van-button
        style="width: 80%; margin: 10px 10%; border-radius: 25px"
        type="info"
        @click="tijiao()"
        >提 交</van-button
      >
    </div>
  </div>
</template>
<script>
import { WeAssessAgency, WeGetAgencyPage } from "@/api/Hyapi";
import Vue from "vue";
import { Rate } from "vant";
import { Dialog } from "vant";
Vue.use(Dialog);
Vue.use(Rate);
export default {
  data() {
    return {
      active: 3,
      datafrom: {
        AId: 0, //中介机构Id（非空），
        StarLevel: 0, //星级（1-一星、2-二星、3-三星、4-四星、5-五星)
        AIdName: "",
        Content: "", //评价内容，
        Status: 1, //状态(1-启用、2-禁用)，
        Remark: "", //备注
      },
      typeshow: false,
      aidList: [{ id: 1, Name: "公司" }],
    };
  },
  mounted() {
    this.GetAgencyPage();
  },
  methods: {
    tijiao() {
      if (this.datafrom.StarLevel == 0) {
        Dialog.alert({
          title: "提示",
          message: "请选择服务评分!",
        }).then(() => {});
        return false;
      }
      if (this.datafrom.Content == 0) {
        Dialog.alert({
          title: "提示",
          message: "请输入服务内容!",
        }).then(() => {});
        return false;
      }
      WeAssessAgency(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.datafrom = {
              AId: 0, //中介机构Id（非空），
              StarLevel: 0, //星级（1-一星、2-二星、3-三星、4-四星、5-五星)
              AIdName: "",
              Content: "", //评价内容，
              Status: 1, //状态(1-启用、2-禁用)，
              Remark: "", //备注
            };
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {});
        }
      });
    },
    ontypeshow(e) {
      console.log(e);
      this.datafrom.AId = e.AId;
      this.datafrom.AIdName = e.Name;
      this.typeshow = false;
    },
    GetAgencyPage() {
      WeGetAgencyPage().then((res) => {
        this.aidList = res.data.data;
      });
    },
  },
};
</script>
<style>
.pinglun .center {
  width: 85%;
  margin: 30px 7.5%;
  background: #e6e6e6;
  border-radius: 8px;
}
.pinglun inputFrom {
  margin: 15px 8%;
}
.pinglun .inputFrom input {
  width: 93%;
  margin: 8px 2%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.pinglun .margin {
  margin: 15px 30px;
}
.pinglun .title {
  padding: 8px 12px;
}
.pinglun .idst {
  padding: 8px 12px;
}
.inputLabel {
  color: #646566;
  font-size: 14px;
  padding: 15px 16px;
}
</style>